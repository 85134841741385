.app-header {
  .ui.menu a.item svg rect,
  .ui.menu a.item svg path {
    stroke-width: 1.2px;
  }
  .ui.menu .active.item:hover,
  .ui.vertical.menu .active.item:hover {
    background-color: transparent;
  }
  .ui.link.menu .item:active,
  .ui.menu .link.item:active,
  .ui.menu a.item:active {
    background-color: transparent;
  }
  .ui.menu.mobile-navmenu {
    margin: 0;
    position: fixed;
    z-index: 850;
    padding: 16px 12px;
    top: -1px;
    width: 100%;
    left: 0;
    border-radius: 0;
    box-shadow: none;

    @media (min-width: 600px) and (max-width: 992px) {
      padding: 0.6em 0.5em 0.6em;
    }
  }
  .ui.menu {
    .right.item {
      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        padding: 0.8em;
        margin-right: 5px;
        svg {
          width: auto;
          height: 18px;
          margin-right: 0.3em;
          margin-bottom: 0 !important;
        }
      }
    }
    .header.item {
      padding: 0.4em;
      border-radius: 0;
    }
  }
  .column {
    padding: 0 0.6em !important;
  }

  .ui.floating.dropdown > .menu {
    right: -10px;
    left: auto;
    top: 30px;
    z-index: 1001;
  }

  .ui.menu .ui.dropdown .menu .active.item {
    color: #00d1c1 !important;
    font-weight: 500 !important;
  }

  .ui.menu.vertical {
    box-shadow: none;
    margin: 1.5em -1.2em;
    width: auto;
  }

  /* Small Width Computer Menu */
  @media (min-width: 993px) and (max-width: 1220px) {
    .ui.borderless.menu {
      padding: 0.2em 0.5em !important;
      .item {
        font-size: 14px;
        padding: 0.5em 0.6em;
        border-radius: 4px;
      }
      .ui.primary.button {
        margin: 0 0.8em !important;
      }
    }
  }

  /* Mobile Menu */
  .ui.menu.mobile-navmenu .header.item {
    padding: 0.2em;
    border-radius: 0;
  }

  .ui.menu.mobile-navmenu .right.item .item {
    padding: 0.2em;
  }

  .mobile-navbar {
    margin-top: 84px;
    padding: 0 6px !important;
    margin-bottom: -10px;

    &.open {
      margin-top: 0;
      height: 100vh;
      display: block !important;
      z-index: 1001;
      background: #fff;
      position: fixed !important;
      left: 0;
      top: 0;
      padding: 0 20px !important;

      & > .wide.column {
        height: 100%;
      }
      .ui.menu.mobile-navmenu {
        box-shadow: none;
      }
      .ui.vertical.menu {
        box-shadow: none;
        .item {
          padding: 14px 0;
        }
      }
      .wrapper {
        background: #fff;
        width: calc(100% + 30px);
        margin: 0 -15px;
        padding: 20px 5px;
        display: block;
        height: 100%;
      }
      .column {
        background: #fff;
      }
    }

    .profile {
      display: -webkit-box;
      display: flex !important;
      align-items: center;
      padding: 1em 5px !important;
      margin-left: 5px;

      .details {
        flex: 1;
        margin-left: 0.6em;

        .top {
          font-size: 14px;
          display: block;
          line-height: 20px;
          color: #7e7e7e;
        }
        .bottom {
          display: block;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #3f3f3f;
        }
      }
    }
    .more-actions-column {
      text-align: left;
      background: #fff;
      border-top: solid 1px rgba(215, 215, 215, 0.5);
      padding: 0 !important;

      .intro {
        font-size: 10px !important;
        text-transform: uppercase;
        font-weight: 600;
        color: #7e7e7e !important;
      }

      .ui.button,
      .ui.button:hover {
        background: #fff;
        box-shadow: none !important;
      }
    }
    @media (min-width: 768px) and (max-width: 992px) {
      .mobile-invest-now-btn {
        margin: 0 -0.5em !important;
        width: calc(100% + 1em) !important;
      }
    }
    @media (max-width: 767px) {
      .mobile-invest-now-btn {
        margin: 0 -1em !important;
        width: calc(100% + 2em) !important;
      }
    }
    @media (min-width: 600px) {
      margin-top: 88px;
    }
  }
}
