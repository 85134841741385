.admin-nav-left {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 16px;
  background: #fff;
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left-wrapper {
      display: flex;
      align-items: center;
      .token-price {
        font-size: 16px;
        font-weight: 600;
        color: #202020;
      }
    }
    img.logo {
      max-width: 140px;
    }
    a.button {
      margin: 0;
      padding: 0 !important;
      background: transparent;
      &:hover {
        background: #ccc;
      }
    }
    .name-wrapper {
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
        font-size: 20px;
        font-weight: 600;
        color: #202020;
      }
    }
  }
  .scroll-wrapper {
    position: relative;
    height: 100%;
    overflow-y: auto;
  }
  .ui.menu {
    position: absolute;
    width: 100% !important;
    border-radius: 0;
    box-shadow: none !important;
    .item {
      font-size: 13px;
      padding: 10px;
      margin-top: 2px;
      margin-bottom: 2px;
      font-weight: 600;
      color: #5f5f5f;
      display: flex !important;
      align-items: center;
      &.active,
      &:hover {
        border-radius: 4px !important;
      }
      svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      .ui.label.red.circular {
        border: none;
        background-color: #f44336;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        padding: 0 !important;
        margin: 0 0 0 6px;
        font-size: 10px;
        line-height: 20px;
      }
    }
    .fairmint-section,
    .manage-portal-section {
      border-top: solid 1px #d7d7d7;
    }
  }
  .completion-percentage-wrapper {
    padding: 16px 0;
    span {
      font-size: 14px;
      font-weight: 600;
      color: #202020;
    }
  }
  .user-info-wrapper {
    padding: 16px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d7d7d7;
    .info-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      .text-wrapper {
        font-size: 12px;
        margin-left: 10px;
        p {
          margin: 0;
        }
        .email {
          font-weight: 700;
          color: #202020;
        }
        .role {
          font-weight: 400;
          color: #5f5f5f;
        }
      }
    }
    .sign-out {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    padding: 16px;
    .header-wrapper {
      height: 40px;
      .mobile-icon {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      img.logo {
        max-height: 40px;
      }
      a.button img,
      a.icon svg {
        width: 30px;
        height: 30px;
        margin-left: 20px;
      }
      a.button:hover {
        background: transparent;
      }
      .name-wrapper span {
        font-size: 24px;
      }
    }
    .ui.menu {
      border-top: solid 1px #d7d7d7 !important;
    }
    .user-info-wrapper {
      .sign-out-mobile {
        color: #202020;
        text-decoration: underline;
        width: fit-content;
      }
    }
  }
}
