.content-menu {
  .menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .flex-wrapper {
      display: flex;
      align-items: center;
    }
    .dropdown-icon {
      width: 12px !important;
      margin: 0 !important;
    }
  }
  .menu-item {
    font-size: 1em;
    font-weight: 600;
    margin-left: 40px;
  }
}
