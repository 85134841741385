.loadingOverlayDiv {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.166);
  text-align: center;
  color: white;
  z-index: 2147483012;

  .loadingCenterWrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .loadingCenterWithText {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .ui.inline.loader {
      top: calc(50% - 54px) !important;
    }
    span.text {
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      width: 270px;
      margin-top: 50px;
      font-weight: 600;
    }
  }
}
