@import "/src/variables.less";

.admin-layout {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  .admin-top-banner-container {
    width: 100%;
    height: @top-banner-height;
  }

  .page-layout {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    @media (max-width: 767px) {
      display: block;
    }

    .admin-nav-left-container {
      width: @left-nav-width;
      height: 100%;
      &.isTablet {
        display: none;
      }
      &.isTablet.mobileMenuOpen {
        display: block;
        position: absolute;
        z-index: 101;
      }
      @media (max-width: 767px) {
        width: 100%;
        height: @left-nav-height-mobile;
        &.mobileMenuOpen {
          height: 100%;
        }
      }
    }
    .main-layout {
      flex: 1;
      position: relative;
      @media (max-width: 767px) {
        height: 100%;
      }

      .tablet-overlay {
        background-color: #2020207f;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
      }

      .scroll-container {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        overflow-y: auto;

        .admin-nav-top-container {
          position: sticky;
          top: -16px;
          z-index: 11;
        }

        .page-content {
          max-width: 1210px;

          &.full-content {
            width: 100% !important;
            height: calc(100% - 100px);
            max-width: 100% !important;
          }

          .ui.grid.pages:first-child {
            padding-left: 12px;
            padding-right: 12px;
            margin-left: initial;
            @media (max-width: 767px) {
              padding-left: 20px;
              padding-right: 20px;
            }
          }
          &.isTablet {
            .ui.grid.pages:first-child {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
