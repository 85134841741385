.avatar {
  display: -webkit-box;
  display: flex;
  align-items: center;
  margin: -10px -6px;

  .avatar-main {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ff875f;
    display: -webkit-box;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 8px 10px rgba(190, 190, 190, 0.118171), 1px 2px 2px #efefef;

    .short-username {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .avatar-details {
    display: -webkit-box;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
    .top {
      color: #7e7e7e;
    }
    .bottom {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.5px;
      color: #3f3f3f;
    }
  }

  @media (min-width: 993px) and (max-width: 1220px) {
    .avatar-main {
      width: 38px;
      height: 38px;
      .short-username {
        font-size: 18px;
      }
    }
    .avatar-details {
      margin-left: 8px;
      font-size: 14px;
      .bottom {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
