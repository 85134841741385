.user-top-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  .content-box {
    width: 1210px;
    height: 64px;
    padding: 0 0.6em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 991px) and (max-width: 1219px) {
      width: 961px;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      color: white;
    }
    .admin-view-btn {
      color: white;
      border-color: white;
    }
  }
}
