@import "/src/variables.less";

.admin-nav-top {
  // shadow effect when scroll down
  &::before,
  &::after {
    content: "";
    display: block;
    height: 16px;
    position: sticky;
    border-top: solid 1px #d7d7d7;
  }
  &::before {
    top: 56px;
    box-shadow: 5px 1px 5px rgba(0, 0, 0, 0.5);
    @media (max-width: 767px) {
      box-shadow: none;
    }
  }
  &::after {
    top: 0;
    background: #f7f7f7;
    z-index: 2;
  }

  .shadow-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: @top-nav-height;
    background: #f7f7f7;
    padding: 20px;
    position: sticky;
    -webkit-position: sticky;
    top: 0px;
    margin-top: -16px;
    z-index: 3;

    .page-title {
      display: flex;
      align-items: center;
      i {
        font-size: 2em;
      }
      .info-icon {
        width: 20px;
        height: 20px;
        margin-left: 12px;
      }
      &.isTablet {
        position: absolute;
        justify-content: center;
        width: 100%;
        z-index: -1;
        padding-right: 40px;
      }
    }
    h2.ui.header {
      font-size: 16px;
      line-height: 24px;
      color: #202020;
      margin: 0;
    }
    .right {
      font-size: 15px;
      line-height: 24px;
      text-align: right;
      letter-spacing: 1px;
      color: #3f3f3f;
      font-weight: 600;
      display: -webkit-box;
      display: flex !important;
      align-items: center;
      justify-content: flex-end;
      .price {
        font-size: 16px;
      }
      .popup-icon {
        margin: 0 0 0 20px;
        padding: 0;
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        background: transparent;
      }
      .popup-icon:hover {
        background: #ccc;
        border-radius: 4px;
      }
      .investor-view-btn {
        margin-left: 20px;
      }
    }
    @media (max-width: 600px) {
      display: block;
      height: auto;
      h2.ui.header {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .right {
        justify-content: flex-start;
        margin-top: 4px;
      }
    }
  }

  .badge-wrapper {
    display: block;
    position: relative;
    width: 13px;
    height: 13px;
    margin-right: 5px;
    .badge-outer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #69bbfb;
      opacity: 0.4;
      border-radius: 50%;
      border: 3px solid transparent;
      box-sizing: border-box;
    }
    .badge-inner {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 7px;
      height: 7px;
      background: #69bbfb;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }

  .back-icon {
    cursor: pointer;
  }

  .tablet-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .mobile-icon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    img.logo {
      max-width: 140px;
    }
    .name-wrapper {
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
        font-size: 20px;
        font-weight: 600;
        color: #202020;
      }
    }
  }
}
