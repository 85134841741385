@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import "react-datepicker/dist/react-datepicker.css";

// Global scss variables (not page specific) should be defined here

html {
  scroll-behavior: smooth;
}

body {
  background: #fff;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Inter" !important;
}

.icon {
  font-family: Icons !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #202020;
}

.text-center {
  text-align: center;
}

.ui.card > .content {
  border-top-color: #d7d7d7;
}

.pages.ui.grid > .row {
  padding: 0;
}
.pages.ui.grid > .row > .column {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  padding: 0 0.6em;
}

body.no-scroll {
  overflow: hidden;
}

@media only screen and (min-width: 1220px) {
  .ui.grid.container {
    width: 1210px !important;
  }
}

@media (min-width: 993px) and (max-width: 1220px) {
  h2.ui.header {
    font-size: 32px;
  }
  .page.ui.grid > .row > .column  {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
}

@media (max-width: 600px) {
  .ui.stackable.grid > .row > .column, .ui.stackable.grid > .row > .wide.column {
    padding: 0px !important;
  }
}

#erxes-messenger-container, #erxes-messenger-iframe {
  z-index: 1002 !important;
}

.intercom-namespace .intercom-launcher-frame, .intercom-lightweight-app {
  z-index: 1002 !important;
}
