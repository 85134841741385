.equityGrantMenu {
  .menuHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .flexWrapper {
      display: flex;
      align-items: center;
    }
    .dropdownIcon {
      width: 12px !important;
      margin: 0 !important;
    }
  }
  .menuItem {
    font-size: 1em;
    font-weight: 600;
    margin-left: 40px;
  }
  .disable {
    background-color: transparent !important;
    color: rgba(40, 40, 40, 0.3) !important;
    margin-left: 40px;
  }
}
