.rich-loading-spinner-overlay-div {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.166);
  text-align: center;
  z-index: 2147483012;

  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0 3em 3em;
    border-radius: 8px;
    box-shadow: 4px 8px 24px rgba(179, 179, 179, 0.06),
      12px 8px 16px rgba(228, 228, 228, 0.2);

    .spinner {
      margin: 50px auto 40px;
      width: 50px;
      height: 40px;
      text-align: center;
      font-size: 10px;
    }

    .spinner > div {
      background-color: #6739fd;
      height: 100%;
      width: 6px;
      margin-right: 3px;
      display: inline-block;

      -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }

    .spinner .rect3 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }

    .spinner .rect4 {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }

    .spinner .rect5 {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }

    @-webkit-keyframes sk-stretchdelay {
      0%,
      40%,
      100% {
        -webkit-transform: scaleY(0.4);
      }
      20% {
        -webkit-transform: scaleY(1);
      }
    }

    @keyframes sk-stretchdelay {
      0%,
      40%,
      100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
      }
      20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
      }
    }

    .text-wrapper {
      p {
        font-size: 18px;
        color: #3f3f3f;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
      }
    }
  }

  @media (max-width: 600px) {
    .wrapper {
      padding: 0 1em 2em;
      .spinner {
        margin: 30px auto 20px;
      }

      .text-wrapper {
        p {
          font-size: 14px !important;
        }
      }
    }
  }
}
